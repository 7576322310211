<template>
  <div class="classifyList" v-if="isShowClassify">
    <div class="classifyList__item flex first">
      <div class="title">一级类目：</div>
      <div class="content flex">
        <span :class="{'active': !firstClassifyID}" @click="changeFirstClassify(0, -1)">不限</span>
        <span
          v-for="(item, index) in classifyList"
          :key="item.id"
          :class="{'active': firstClassifyID === item.id}"
          @click="changeFirstClassify(item.id, index)"
        >{{item.name}}</span>
      </div>
    </div>
    <div class="classifyList__item flex second" v-if="catalogueType != 1">
      <div class="title">二级类目：</div>
      <div class="content flex">
        <span :class="{'active': !secondClassifyID}" @click="changeSecondClassify(null)">不限</span>
        <template>
          <span
            :class="{'active': secondClassifyID === items.id}"
            v-for="items in contentsList"
            :key="items.id"
            @click="changeSecondClassify(items.id)"
          >{{items.name}}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { cateList } from '@/api/index/index'
export default {
  name: 'RegionalMembers',
  props: {
    catalogueType: {
      type: Number,
      default: 0
    },
    firstId: {
      type: String,
      default: ''
    }
  },
  watch: {
    firstId(val) {
      this.firstClassifyID = parseInt(val)
    }
  },
  data() {
    return {
      isShowClassify: true,
      secondClassifyID: 0,
      firstClassifyID: 0,
      firstClassifyIndex: 0,
      classifyList: [], // 一级目录
      contentsList: [], // 二级目录
      level: 1,
      level_id: '' // 二级目录id
    }
  },
  created() {
    this.regionalMmbers()
  },
  methods: {
    changeSecondClassify(id) {
      this.secondClassifyID = id
      this.$emit('searchProjectOne', id)
    },
    changeFirstClassify(id, index) {
      this.firstClassifyID = id
      if (index === -1) {
        this.level = 1
      } else {
        this.level = 0
      }
      this.regionalMmbersTwo(id)
      this.$emit('searchProjectTwo', id)
    },
    regionalMmbers() { // 一级目录
      const data = {
        pid: 0
      }
      cateList(data).then(res => {
        this.classifyList = res.data
        if (this.firstId) {
          this.regionalMmbersTwo(this.firstClassifyID)
          return
        }
        // this.level = 1
        this.regionalMmbersTwo(null)
      })
    },
    regionalMmbersTwo(pid) { // 二级目录
      const data = {
        pid,
        level: this.level
      }
      cateList(data).then(res => {
        // console.log(res.data)
        this.contentsList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .classifyList{
      background: #fff;
      &__item{
        font-size: 16px;
        .title{
          margin-right: 40px;
          height: 26px;
          line-height: 26px;
        }
        .content{
          flex-wrap: wrap;
          flex: 1;
          span{
            margin-right: 30px;
            margin-bottom: 10px;
            height: 26px;
            line-height: 26px;
            padding: 0 8px;
            border-radius: 4px;
            &:hover:not(.active){
              cursor: pointer;
              color: #1166CF;
            }
            &.active{
              background: #E7F2FF;
              color: #1166CF;
            }
          }
        }
        &.first{
          border-bottom: 1px solid #EEE;
          padding-bottom: 20px;
        }
        &.second{
          border-bottom: 1px solid #EEE;
          padding: 20px 0;
        }
      }
    }
</style>
