<template>
  <div class="classifyList">
    <div class="classifyList__item flex first">
      <div class="title">{{filter === 0 ? '项目筛选：' : '会员筛选：'}}</div>
      <div class="content flex">
        <span
          v-for="(item, index) in classifyList"
          :key="item.sort"
          :class="{'active': secondClassifyID === item.sort}"
          @click="changeFilter(item.sort, index)"
        >{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegionalMembers',
  props: {
    filter: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      secondClassifyID: 1,
      classifyList: [
        {
          name: '最新',
          sort: 1
        },
        {
          name: '最热',
          sort: 2
        }
      ]
    }
  },
  methods: {
    changeFilter(id) {
      this.secondClassifyID = id
      this.$emit('changeFilter', id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .classifyList{
      background: #fff;
      border-top: 1px solid #EEE;
      &__item{
        font-size: 16px;
        .title{
          margin-right: 40px;
          height: 26px;
          line-height: 26px;
        }
        .content{
          flex-wrap: wrap;
          flex: 1;
          span{
            margin-right: 30px;
            // margin-bottom: 10px;
            height: 26px;
            line-height: 26px;
            padding: 0 8px;
            border-radius: 4px;
            &:hover:not(.active){
              cursor: pointer;
              color: #1166CF;
            }
            &.active{
              background: #E7F2FF;
              color: #1166CF;
            }
          }
        }
        &.first{
          border-bottom: 1px solid #EEE;
          padding: 20px 0;
        }
        &.second{
          border-bottom: 1px solid #EEE;
          padding: 20px 0;
        }
      }
    }
</style>
